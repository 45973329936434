@import "~@ui/styles/tools";
@import "../../styles/variables";

.item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    width: 100%;
    padding: 20px 24px;
    border-radius: 12px;
    height: 136px;   
    
    @include media-tablet {
        padding: 16px 20px;
        height: 123px;
    }

    @include media-tablet-portrait {
        height: 108px;
    }

    @include media-mobile {
        gap: 16px;
    }

    &__background {
        background: $color-page-background;
    }

    &__vertical {
        flex: 1;
        width: auto;
        flex-direction: column;
        align-items: flex-start;
        padding: 28px 24px;
        height: 100%;

        @include media-table-large {
            padding: 20px 16px;
        }

        @include media-tablet-portrait {
            flex: unset;
            width: 100%;
            flex-direction: row;
            align-items: center;
            padding: 16px;
            height: 108px;
        }

        .item__title {
            font-size: 32px;

            @include media-desktop-md {
                font-size: 24px;
            }

            @include media-tablet {
                font-size: 18px;
            }

            @include media-tablet-portrait {
                font-size: 24px;
            }

            @include media-mobile {
                font-size: 14px;
            }
        }

        .item__broker {
            flex-direction: column;
            align-items: flex-start;

            @include media-tablet-portrait {
                flex-direction: row;
                align-items: center;
            }
        }

        .item__price {
            align-items: flex-start;

            @include media-tablet-portrait {
                align-items: flex-end;
            }
        }

        .item__label {
            @include media-tablet {
                font-size: 14px;
            }
        }

        .item__value {
            font-size: 40px;

            @include media-desktop-md {
                font-size: 32px;
            }

            @include media-table-large {
                font-size: 24px;
            }

            @include media-tablet {
                font-size: 20px;
            }

            @include media-mobile {
                font-size: 18px;
            }
        }

        .item__content {
            align-items: flex-start;

            @include media-tablet {
                gap: 8px;
            }

            @include media-tablet-portrait {
                align-items: flex-end;
            }
        }

        .item__url {
            @include media-tablet {
                font-size: 16px;
            }

            @include media-mobile {
                font-size: 14px;
            }
        }
    }

    &__small {
        padding: 0;
        min-width: fit-content;
        gap: 15px;
    
        .item__broker {
            flex-direction: row;
        }
        
        .item__icon {
            min-width: 28px;
            min-height: 28px;
        }
        
        .item__title {
            font-weight: 500;
            font-size: 20px;
            line-height: 126%;
            letter-spacing: 0px;

            @include media-table-large {
                max-width: 150px;
                font-size: 18px;
            }
        }

        .item__label {
            font-weight: 400;
            font-size: 16px;
            line-height: 120%;
            letter-spacing: 0px;
        }

        .item__value {
            font-weight: 400;
            font-size: 24px;
            line-height: 100%;
            letter-spacing: 0px;

            @include media-table-large {
                font-size: 20px;
            }
        }
    }

    &__broker {
        display: flex;
        align-items: center;
        gap: 16px;

        @include media-tablet {
            gap: 12px;
        }
        
        @include media-mobile {
            gap: 6px;
        }
    }

    &__icon {
        min-height: 44px;
        min-width: 44px;
        position: relative;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #E2E3E4;

        > img {
            object-fit: cover;
        }
    }

    &__title {
        font-size: 24px;
        line-height: 1.2;
        font-weight: 500;
        color: $color-title-active;

        @include media-table-large {
            font-size: 22px;
        }

        @include media-tablet {
            font-size: 18px;
        }

        @include media-tablet-portrait {
            font-size: 24px;
        }

        @include media-mobile {
            font-size: 16px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 16px;

        @include media-tablet {
            gap: 12px;
        }

        @include media-tablet-portrait {
            gap: 8px;
        }
    }

    &__price {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 8px;
    }

    &__label {
        font-size: 16px;
        line-height: 1.2;
        font-weight: 400;
        color: $color-body;

        @include media-table-large {
            font-size: 14px;
        }
    }

    &__value {
        font-size: 24px;
        line-height: 1.2;
        font-weight: 400;
        color: $color-title-active;
        white-space: nowrap;

        @include media-table-large {
            font-size: 22px;
        }

        @include media-tablet {
            font-size: 20px;
        }
    }

    &__url {
        font-size: 20px;
        line-height: 1.2;
        font-weight: 500;
        color: $color-primary;

        @include media-table-large {
            font-size: 18px;
        }


        @include media-tablet  {
            font-size: 16px;
        }
    }
}